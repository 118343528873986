@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
  overflow: hidden;
  @apply bg-neutral-90 sm:overflow-auto;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2c2c2c;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  @apply text-xxxl font-bold;
}

h2 {
  @apply text-xxl font-bold;
}

h3 {
  @apply text-xl font-bold;
}

h4 {
  @apply text-l font-bold;
}

h5 {
  @apply text-m font-semibold;
}

a {
  @apply font-semibold text-bluetiful-50 cursor-pointer underline underline-offset-4;
}

a:hover {
  @apply text-bluetiful-40;
}

/* Quill Editor List Styles */
li[data-list='ordered'],
li[data-list='bullet'] {
  list-style-type: none;
  padding-left: 0.5em;
  position: relative;
  line-height: 22px;
  counter-increment: list-0;
}

li[data-list='ordered'] > .ql-ui::before,
li[data-list='bullet'] > .ql-ui::before {
  content: counter(list-0, decimal) '. ';
}

li[data-list='ordered'].ql-indent-1,
li[data-list='bullet'].ql-indent-1 {
  counter-increment: list-1;
}

li[data-list='ordered'].ql-indent-1 > .ql-ui::before,
li[data-list='bullet'].ql-indent-1 > .ql-ui::before {
  content: counter(list-1, lower-alpha) '. ';
}

li[data-list='ordered'].ql-indent-2,
li[data-list='bullet'].ql-indent-2 {
  counter-increment: list-2;
}

li[data-list='ordered'].ql-indent-2 > .ql-ui::before,
li[data-list='bullet'].ql-indent-2 > .ql-ui::before {
  content: counter(list-2, lower-roman) '. ';
}

li[data-list='ordered'].ql-indent-3,
li[data-list='bullet'].ql-indent-3 {
  counter-increment: list-3;
}

li[data-list='ordered'].ql-indent-3 > .ql-ui::before,
li[data-list='bullet'].ql-indent-3 > .ql-ui::before {
  content: counter(list-3, decimal) '. ';
}

li[data-list='ordered'].ql-indent-4,
li[data-list='bullet'].ql-indent-4 {
  counter-increment: list-4;
}

li[data-list='ordered'].ql-indent-4 > .ql-ui::before,
li[data-list='bullet'].ql-indent-4 > .ql-ui::before {
  content: counter(list-4, lower-alpha) '. ';
}

li[data-list='ordered'].ql-indent-5,
li[data-list='bullet'].ql-indent-5 {
  counter-increment: list-5;
}

li[data-list='ordered'].ql-indent-5 > .ql-ui::before,
li[data-list='bullet'].ql-indent-5 > .ql-ui::before {
  content: counter(list-5, lower-roman) '. ';
}

li[data-list='ordered'].ql-indent-6,
li[data-list='bullet'].ql-indent-6 {
  counter-increment: list-6;
}

li[data-list='ordered'].ql-indent-6 > .ql-ui::before,
li[data-list='bullet'].ql-indent-6 > .ql-ui::before {
  content: counter(list-6, decimal) '. ';
}

li[data-list='ordered'].ql-indent-7,
li[data-list='bullet'].ql-indent-7 {
  counter-increment: list-7;
}

li[data-list='ordered'].ql-indent-7 > .ql-ui::before,
li[data-list='bullet'].ql-indent-7 > .ql-ui::before {
  content: counter(list-7, lower-alpha) '. ';
}

li[data-list='ordered'].ql-indent-8 {
  counter-increment: list-8;
}

li[data-list='ordered'].ql-indent-8 > .ql-ui::before {
  content: counter(list-8, lower-roman) '. ';
}

li[data-list='ordered'].ql-indent-9 {
  counter-increment: list-9;
}

li[data-list='ordered'].ql-indent-9 > .ql-ui::before {
  content: counter(list-9, decimal) '. ';
}

li[data-list='bullet'] > .ql-ui::before {
  content: '\2022';
}
