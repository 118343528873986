$primaryColor: #4d7ae3 !default;
$primaryLightColor: #c7d2fe !default;
$primaryDarkColor: #4f46e5 !default;
$primaryDarkerColor: #4338ca !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #eef2ff !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import '../_variables';
@import '../../../../theme-base/_components';
@import '../_extensions';
