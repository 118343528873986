@use 'sass:math';

//core
.p-inputswitch {
  position: relative;
  display: inline-block;
}

.p-inputswitch-input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1;
  outline: 0 none;
  cursor: pointer;
}

.p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
}

.p-inputswitch-slider:before {
  position: absolute;
  content: '';
  top: 50%;
}

//theme
.p-inputswitch {
  width: $inputSwitchWidth;
  height: $inputSwitchHeight;

  .p-inputswitch-input {
    border-radius: $inputSwitchBorderRadius;
  }

  .p-inputswitch-slider {
    background: $inputSwitchSliderOffBg;
    transition: $formElementTransition;
    border-radius: $inputSwitchBorderRadius;
    outline-color: transparent;

    &:before {
      background: $inputSwitchHandleOffBg;
      width: $inputSwitchHandleWidth;
      height: $inputSwitchHandleHeight;
      left: $inputSwitchSliderPadding;
      margin-top: math.div(-1 * $inputSwitchHandleHeight, 2);
      border-radius: $inputSwitchHandleBorderRadius;
      transition-duration: $transitionDuration;
    }
  }

  &.p-highlight {
    .p-inputswitch-slider {
      background: $inputSwitchSliderOnBg;

      &:before {
        background: $inputSwitchHandleOnBg;
        transform: translateX($inputSwitchHandleWidth);
      }
    }
  }

  &:not(.p-disabled) {
    &:has(.p-inputswitch-input:hover) {
      .p-inputswitch-slider {
        background: $inputSwitchSliderOffHoverBg;
      }

      &.p-highlight {
        .p-inputswitch-slider {
          background: $inputSwitchSliderOnHoverBg;
        }
      }
    }

    &:has(.p-inputswitch-input:focus-visible) {
      .p-inputswitch-slider {
        @include focused();
      }
    }
  }

  &.p-invalid > .p-inputswitch-slider {
    @include invalid-input();
  }
}
